<template>
  <div class="rounded-lg pa-1 bg-white mr-1 ml-1">
    <v-menu :open-on-hover="!store.onMobile" open-delay=0>
      <template v-slot:activator="{props}">
        <v-btn v-bind="props" variant="text" size="35">
          <v-img width="30" height="30" :src="require('@/assets/imgs/ForceSketchLogo.svg')" />
        </v-btn>
      </template>
      <v-card>
        <v-list density="compact">
          <v-list-item @click="$router.push('/dashboard')" 
            v-if="($router.currentRoute.value.fullPath != '/dashboard' &&  $router.currentRoute.value.fullPath != '/')&& store.getUser">
            <template v-slot:prepend>
              <v-icon icon="mdi: mdi-view-dashboard-outline"></v-icon>
            </template>
            Dashboard
          </v-list-item>
          <v-list-item value="Documentation" 
            href="https://core-studio.gitbook.io/forcesketch/" target="_blank" 
            style="color: inherit; text-decoration: none;">
            <template v-slot:prepend>
              <v-icon icon="mdi: mdi-book-open-outline"></v-icon>
            </template>
            Documentation
          </v-list-item>
          <v-list-item value="Contact"
            href="https://core-studio.gitbook.io/forcesketch/contact" target="_blank" style="color: inherit; text-decoration: none;">
            <template v-slot:prepend>
              <v-icon icon="mdi: mdi-email-outline"></v-icon>
            </template>
            Contact
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { useStore } from '@/store/store'
import toggleButton from '@/components/ui/toggleButton.vue'
import History from "@/utilities/History";
import * as modifiers from '@/utilities/Modifiers'

export default {
  components: { toggleButton},
  setup(){
    const store = useStore()
    return {store, History, modifiers}
  },
  data: () => ({
    
  }),
  created(){
  },
  computed: {},
  mounted() {},
}
</script>