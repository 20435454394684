<template>
  <div class="rounded-lg py-1 bg-white elevation-1 btnContainer">
    <toggleButton 
      @click="dialog = true"
      :tooltip="'Settings'">
      <v-icon>fa:fas fa-gear</v-icon>
    </toggleButton>

    <v-dialog v-model="dialog" width="600px" height="70%" @update:modelValue="dialogClosed">
      <v-card class="pb-6">
        <v-card-title class="d-flex mt-2 align-center justify-space-between">
          <div class="d-flex align-center">
            <v-icon>fa:fas fa-gear</v-icon>
            <div class="text-h4 ml-2">Settings</div>
          </div>
          <v-btn @click="dialog=false, dialogClosed()"
            icon="mdi:mdi-close" variant="plain">
          </v-btn>
        </v-card-title>
        <v-row class="px-6 pt-2">
        <v-alert v-if="store.analyzeMode"
          icon="mdi:mdi-lock"
          text="Return to edit mode to modify settings" 
          type="warning" border="start">
        </v-alert>
        </v-row>
        
        <v-row style="margin-left:-2px">
          <v-col cols="7">
            <v-card-item>
              <div class="text-h6" style="color: rgb(var(--v-theme-purple))">Beam Definition</div>
              <div class="text-grey" style="width:300px">
                Includes all elements that are less than
              </div>
              <div class="d-flex">
                <div style="width:auto">
                  <NumberInput :input="Number(store.beamDegreesFromHorz)" :disable="!canEdit"
                  @valueChange="valueChange($event, 'beamDegreesFromHorz')"/>
                </div>
                <span class="d-flex align-center text-grey ml-1">degrees from horizontal.</span>
              </div>
            </v-card-item>
            <v-card-item>
              <div class="text-h6" style="color: rgb(var(--v-theme-green))">Column Definition</div>
              <div class="text-grey" style="width:300px">
                Includes all elements that are less than
              </div>
              <div class="d-flex">
                <div style="width:auto">
                  <NumberInput :input="Number(store.columnDegreesFromVert)" :disable="!canEdit"
                  @valueChange="valueChange($event, 'columnDegreesFromVert')"/>
                </div>
                <span class="d-flex align-center text-grey ml-1">degrees from vertical.</span>
              </div>
            </v-card-item>
            <v-card-item>
              <div class="text-h6" style="color: rgb(var(--v-theme-primary))">Brace Definition</div>
              <div class="text-grey" style="width:300px">
                All elements that don’t fall under the ‘Beam’ or ‘Column’ definition
              </div>
            </v-card-item>
          </v-col>
          <!-- image -->
          <v-col class="d-flex align-center" cols="5">
            <div  style="width:200px">
            <v-img width="200px"
              :src="require('@/assets/imgs/FrameDefinitions.png')">
            </v-img>
            </div>
          </v-col>
        </v-row>

        <v-divider></v-divider>

        <v-card-item>
          <div class="text-h6">Gravity</div>
          <div class="d-flex align-center">
            <v-switch 
              inset hide-details color="primary" 
              :label="store.gravity ? 'On' : 'Off'" 
              :disabled="store.userPermissions == 'Viewer' || !canEdit"
              :model-value="store.gravity" 
              @update:modelValue="store.toggleGravity">
            </v-switch>
          </div>
        </v-card-item>

        <v-divider></v-divider>

        <v-card-item>
          <div class="text-h6">Units</div>
          <div class="text-grey" style="width:300px" v-if="canEdit">
            <v-btn 
              @click="store.changeUnits('metric')"
              variant="outlined" 
              class="rounded-0 rounded-s" 
              :class="store.units == 'metric' ? 'selected' : 'notSelected'"
              :disabled="store.userPermissions == 'Viewer' && store.units != 'metric'"
            >
              metric
            </v-btn>
            <v-btn 
              @click="store.changeUnits('imperial')"
              variant="outlined" 
              class="rounded-0 rounded-e" 
              style="margin-left: -1px"
              :class="store.units == 'imperial' ? 'selected' : 'notSelected'"
              :disabled="store.userPermissions == 'Viewer' && store.units != 'imperial'"
            >
              imperial
            </v-btn>
          </div>
          <div v-else>
            <v-btn 
              variant="outlined" 
              class="selected" 
              disabled
            >
              {{store.units}}
            </v-btn>
          </div>
        </v-card-item>
        
        <div class="d-flex px-2 mt-2">
          <div>
            <v-card-item>
              <div class="text-h6">Point Load Units</div>
              <div class="text-grey" style="width:300px" v-if="canEdit">
                <v-btn 
                  @click="store.setPointLoadUnit('lbsN')"
                  variant="outlined" 
                  class="rounded-0 rounded-s" 
                  :class="store.pointLoadImperial == 'lbs' ? 'selected' : 'notSelected'"
                  :disabled="store.userPermissions == 'Viewer' && store.units != 'metric'"
                >
                  {{store.units == 'imperial' ? 'lbs' : 'N'}}
                </v-btn>
                <v-btn 
                  @click="store.setPointLoadUnit('kipskN')"
                  variant="outlined" 
                  class="rounded-0 rounded-e" 
                  style="margin-left: -1px"
                  :class="store.pointLoadImperial == 'kips' ? 'selected' : 'notSelected'"
                  :disabled="store.userPermissions == 'Viewer' && store.units != 'imperial'"
                >
                  {{store.units == 'imperial' ? 'kips' : 'kN'}}
                </v-btn>
              </div>
              <div v-else>
                <v-btn 
                  variant="outlined" 
                  class = "selected" 
                  disabled
                >
                  {{store.units=='metric' ? store.pointLoadMetric : store.pointLoadImperial}}
                </v-btn>
              </div>
            </v-card-item>
            <v-card-item>
              <div class="text-h6">Line Load Units</div>
              <div class="text-grey" style="width:300px" v-if="canEdit">
                <v-btn 
                  @click="store.setLineLoadUnit('lbsN')"
                  variant="outlined" 
                  class="rounded-0 rounded-s" 
                  :class="store.lineLoadImperial == 'lbs' ? 'selected' : 'notSelected'"
                  :disabled="store.userPermissions == 'Viewer' && (store.lineLoadImperial=='kips' || store.lineLoadMetric =='kN')"
                >
                  {{store.units == 'imperial' ? 'lbs' : 'N'}}/{{store.getLengthUnit}}
                </v-btn>
                <v-btn 
                  @click="store.setLineLoadUnit('kipskN')"
                  variant="outlined" 
                  class="rounded-0 rounded-e" 
                  style="margin-left: -1px"
                  :class="store.lineLoadImperial == 'kips' ? 'selected' : 'notSelected'"
                  :disabled="store.userPermissions == 'Viewer' && (store.lineLoadImperial=='lbs' || store.lineLoadMetric =='kN')"
                >
                  {{store.units == 'imperial' ? 'kips' : 'kN'}}/{{store.getLengthUnit}}
                </v-btn>
              </div>
              <div v-else>
                <v-btn 
                  variant="outlined" 
                  class = "selected" 
                  disabled
                >
                  {{store.units=='metric' ? store.lineLoadMetric : store.lineLoadImperial}}/{{store.getLengthUnit}}
                </v-btn>
              </div>
            </v-card-item>
          </div>
          <div>
            <v-card-item>
              <div class="text-h6">Deflection Units</div>
              <div class="text-grey" style="width:300px" v-if="canEdit">
                <v-btn 
                  @click="store.setDeflectionUnit('incm')"
                  variant="outlined" 
                  class="rounded-0 rounded-s" 
                  :class="store.deflectionUnitImperial == 'in' ? 'selected' : 'notSelected'"
                  :disabled="store.userPermissions == 'Viewer' && store.deflectionUnitImperial != 'in'"
                >
                  {{store.units == 'imperial' ? 'in' : 'cm'}}
                </v-btn>
                <v-btn 
                  @click="store.setDeflectionUnit('ftm')"
                  variant="outlined" 
                  class="rounded-0 rounded-e" 
                  style="margin-left: -1px"
                  :class="store.deflectionUnitImperial == 'ft' ? 'selected' : 'notSelected'"
                  :disabled="store.userPermissions == 'Viewer' && store.deflectionUnitImperial != 'ft'"
                >
                  {{store.units == 'imperial' ? 'ft' : 'm'}}
                </v-btn>
              </div>
              <div v-else>
                <v-btn 
                  variant="outlined" 
                  class = "selected" 
                  disabled
                >
                  {{store.units=='metric' ? store.deflectionUnitMetric : store.deflectionUnitImperial}}
                </v-btn>
              </div>
            </v-card-item>
            <v-card-item>
              <div class="text-h6">Resultant Force Units</div>
              <div class="text-grey" style="width:300px" v-if="canEdit">
                <v-btn 
                  @click="store.setResultantForceUnit('lbsN')"
                  variant="outlined" 
                  class="rounded-0 rounded-s" 
                  :class="store.resultantForceUnitImperial == 'lbs' ? 'selected' : 'notSelected'"
                  :disabled="store.userPermissions == 'Viewer' && store.resultantForceUnitImperial != 'lbs'"
                >
                  {{store.units == 'imperial' ? 'lbs' : 'N'}}
                </v-btn>
                <v-btn 
                  @click="store.setResultantForceUnit('kipskN')"
                  variant="outlined" 
                  class="rounded-0 rounded-e" 
                  style="margin-left: -1px"
                  :class="store.resultantForceUnitImperial == 'kips' ? 'selected' : 'notSelected'"
                  :disabled="store.userPermissions == 'Viewer' && store.resultantForceUnitImperial != 'kips'"
                >
                  {{store.units == 'imperial' ? 'kips' : 'kN'}}
                </v-btn>
              </div>
              <div v-else>
                <v-btn 
                  variant="outlined" 
                  class = "selected" 
                  disabled
                >
                  {{store.units=='metric' ? store.resultantForceUnitMetric : store.resultantForceUnitImperial}}
                </v-btn>
              </div>
            </v-card-item>
          </div>
        </div>

        <!-- <v-divider></v-divider>

        <v-card-item>
          <div class="text-h6">Results Sig Digits</div>
          <div style="width:auto">
            <NumberInput :input="store.resultsSigDigits"
            @valueChange="valueChange($event, 'resultsSigDigits')"/>
          </div>
        </v-card-item> -->

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { useStore } from '@/store/store'
import toggleButton from '@/components/ui/toggleButton.vue'
import History from "@/utilities/History";
import * as modifiers from '@/utilities/Modifiers'
import NumberInput from '@/components/ui/NumberInput.vue';
import GravityIcon from '../assets/icons/gravityIcon.vue'
import * as utils from '@/utilities/Utils.js'

export default {
  components: { 
    toggleButton,
    NumberInput,
    GravityIcon
  },
  setup(){
    const store = useStore()
    return {store, History, modifiers}
  },
  data: () => ({
    dialog: false,
    frameDefinitionsUpdated: false
  }),
  created(){},
  computed: {
    canEdit(){
      if (!(this.store.userPermissions == 'Viewer' || this.store.analyzeMode)){
        return true
      }
      else return false
    }
  },
  mounted() {},
  methods: {
    valueChange(e, prop){
      if (prop.includes('beam') || prop.includes('column')){
        this.frameDefinitionsUpdated = true
      }
      this.store[prop] = Number(e)
    },
    dialogClosed(){
      if (this.frameDefinitionsUpdated){
        //Update all frame types in canvas
        this.store.drawingLayer.children.forEach(frame => {
          frame.data.frameProps.type = utils.getFrameType(frame)
        });
        this.store.snackbarText = 'Frame Definitions Updated'
        this.store.snackbar = true
      }
      this.frameDefinitionsUpdated = false
    }
  },
}
</script>

<style scoped>
.selected{
  background: rgb(var(--v-theme-secondary));
  color: black
}
.notSelected{
  background: white;
  color: black
}
</style>