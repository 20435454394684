<template>
  <div class="d-flex" style="">
    
    <div class="rounded-lg pa-1 d-flex bg-white">
      <toggleButton
        :tooltip="'Show Values'"
        @click="store.toggleShowValues()"
        :active="store.showValues">
        123
      </toggleButton>
      <toggleButton
        :tooltip="'Show Reactions'"
        @click="store.toggleShowReactions()"
        :active="store.reactionLayer.visible">
        <v-icon>mdi-arrow-collapse-up</v-icon>
      </toggleButton>
    </div>
    
    <v-divider vertical thickness="1" class="border-opacity-100 mx-1 my-2"></v-divider>

    <div class="rounded-lg pa-1 d-flex bg-white">
      <!-- D, V, M, A -->
      <ButtonDropdown :active="store.deflectionLayer.visible" @btnClick="store.toggleAnalysisLayerView(store.deflectionLayer)"
        :icon="'fa:fas fa-d'" :tooltip="'Deflection'" :prop="'deflectionScale'"/>
      <ButtonDropdown :active="store.shearLayer.visible" @btnClick="store.toggleAnalysisLayerView(store.shearLayer)"
        :icon="'fa:fas fa-v'" :tooltip="'Shear'" :prop="'shearScale'"/>
      <ButtonDropdown :active="store.momentLayer.visible" @btnClick="store.toggleAnalysisLayerView(store.momentLayer)"
        :icon="'fa:fas fa-m'" :tooltip="'Moment'" :prop="'momentScale'"/>
      <ButtonDropdown :active="store.axialLayer.visible" @btnClick="store.toggleAnalysisLayerView(store.axialLayer)"
      :icon="'fa:fas fa-a'" :tooltip="'Axial'" :prop="'axialScale'"/>

      <ButtonDropdown 
        :active="store.utilizationLayer.visible" 
        @btnClick="store.toggleAnalysisLayerView(store.utilizationLayer)"
        :icon="'fa-solid fa-u'" 
        :tooltip="'Utilization'" 
        :prop="'utilizationScale'"
        :slot="true">
        <template v-slot:menu>
          <div style="width: 250px">
            <v-range-slider 
              class="CustomSlider resizeGradientRange align-center pt-1 pb-0 pl-2 pr-3 mb-6" 
              :style="getGradientCSS"
              v-model="utilizationRange" 
              :max="100" 
              :min="0" 
              thumb-color="grey lighten-23"
              :ticks="getGradientLabels"
              tick-size="3"
              show-ticks="always"
              hide-details
              color="black" 
              track-color="darkGrey"
              @update:modelValue="setRange($event)"
            ></v-range-slider>
          </div>
        </template>
      </ButtonDropdown>

      <!-- Mode Shapes -->
      <ButtonDropdown 
        :active="store.analysisLayers.mode.visible" 
        @btnClick="store.toggleModeShape()"
        :tooltip="'Mode Shapes'"
        :slot="true"
        >
        <template v-slot:my-icon>
          <WaveIcon :color="'black'" width="20" height="20"/>
        </template>
        <template v-slot:menu>
          <div 
            class="d-flex align-center pa-2"
            style="height: 48px; 
            margin-left: -15px;
            border-radius: 0px 10px 10px 0px"
          > 
            <div class="d-flex align-center justify-space-between">
              <v-btn @click="changeMode('down')" size=25 color="grey-darken-1" class="ml-4 pa-0" variant="outlined" density="compact">
                <v-icon>mdi:mdi-chevron-down</v-icon>
              </v-btn>
              <span class="pl-1 pr-1">Mode: {{Number(store.mode)}} </span>
              <v-btn @click="changeMode('up')" 
                size=25 color="grey-darken-1" class="mr-2 " variant="outlined" density="compact">
                <v-icon>mdi:mdi-chevron-up</v-icon>
              </v-btn>
            </div>
            <span>| f: {{Math.round(store.frequency*100)/100}} hz | T: {{Math.round(store.period*10000)/10000}} s</span>
          </div>
        </template>
      </ButtonDropdown> 
    </div>

    <v-divider vertical class="border-opacity-100 mx-1 my-2"/>

    <!-- Close -->
    <div class="rounded-lg pa-1 d-flex bg-white">
      <toggleButton :tooltip="'Return To Edit Mode'" @click="store.toggleAnalyzeMode()">
        <v-icon size="30">
          mdi:mdi-close
        </v-icon>
      </toggleButton>
    </div>

  </div>
</template>

<script>
import { useStore } from '@/store/store'
import toggleButton from '@/components/ui/toggleButton.vue'
import History from "@/utilities/History";
import * as modifiers from '@/utilities/Modifiers'
import lassoIcon from '@/assets/icons/lassoIcon.vue'
import fixIcon from '@/assets/icons/fixIcon.vue'
import rollerX from '@/assets/icons/rollerX.vue'
import rollerY from '@/assets/icons/rollerY.vue'
import lineLoad from '@/assets/icons/lineLoad.vue'
import gravityIcon from '../assets/icons/gravityIcon.vue'
import SectionSizeDropDown from '@/components/ui/SectionSizeDropDown.vue'
import NumberInput from '@/components/ui/NumberInput.vue'
import SnapToGrid from '@/assets/icons/SnapToGrid.vue';
import GridOn from '@/assets/icons/GridOn.vue'
import {maxZoomIn, maxZoomOut} from '@/viewportControls/zoom.js'
import ButtonDropdown from '@/components/ui/ButtonDropdown.vue'
import WaveIcon from '@/assets/icons/waveIcon.vue'
import {gradientColorsCSS} from '@/constants/gradient.js'

export default {
  components: { 
    toggleButton, 
    lassoIcon, 
    fixIcon, 
    rollerX, 
    rollerY, 
    lineLoad, 
    gravityIcon, 
    SectionSizeDropDown,
    NumberInput,
    SnapToGrid,
    GridOn,
    ButtonDropdown,
    WaveIcon
    },
  setup(){
    const store = useStore()
    return {store, History, modifiers}
  },
  data: () => ({
    snackbar: false,
    utilizationRange: [0, 100]
  }),
  created(){

  },
  computed: {
    getGradientCSS() {
      let cssObj = {};
      cssObj['--background-image'] = `linear-gradient(to right, ${gradientColorsCSS.join(", ")})`;
      cssObj['--gradientHeight'] = '25px';
      cssObj['--labelAngle'] = 0 +'deg';
      return cssObj;
    },
    getGradientLabels(){
      const count = 5
      const min = 0
      const max = 100
      const step = (max - min) / count;

      let marks = [];
      for (let i = 0; i <= count; i++) {
        marks.push((min + step * i));
      }

      return marks;
    },
  },
  mounted() {},
  methods: {
    isActive(prop){
      if (this.store[prop]) return true
      else return false
    },
    toggleGravity(){
      this.store.gravity = !this.store.gravity
      this.snackbar = true
    },
    isActiveSubTool(toolName){
      if (this.store.subTool == toolName) return true
      else return false
    },
    valueChange(e, prop){
      this.store[prop] = Number(e)
    },
    zoomOut(){
      let newZoom = this.store.canvas.view.zoom - 0.2
      if (newZoom < maxZoomOut) newZoom = maxZoomOut
      this.store.canvas.view.zoom = newZoom
    },
    zoomIn(){
      let newZoom = this.store.canvas.view.zoom + 0.2
      if (newZoom > maxZoomIn) newZoom = maxZoomIn
      this.store.canvas.view.zoom = newZoom
    },
    changeMode(direction){
      if (direction == 'up'){
        if (this.store.mode == 12) this.store.mode = 0
        this.store.mode++
        this.store.analyze.redrawModeShape()
      }
      else {
        if (this.store.mode == 1) this.store.mode = 13
        this.store.mode--
        this.store.analyze.redrawModeShape()
      }
    },
    setRange(range){
      console.log(this.store.tools)
      this.store.analyze.recolorUtilization(range[0]/100, range[1]/100)
    }
  }
}
</script>

<style scoped>
  .v-input--horizontal:deep(.v-slider-track__background){
    height: var(--gradientHeight) !important;
    border-radius: 0 !important;
  }
  .v-input--horizontal:deep(.v-slider-track__fill.bg-black){
    height: var(--gradientHeight) !important;
    background-image: var(--background-image) !important;
    border-radius: 0 !important;
  }
  .v-input--horizontal:deep(.v-slider-track__tick-label){
    transform: translateX(0%) rotate(var(--labelAngle)) translateY(calc(var(--gradientHeight)/2.2)) !important;
    transform-origin: center left !important;
    font-size: x-small !important;
  }
  .v-input:deep(.text-grey){
    color: #EEEEEE !important;
  }
</style>
