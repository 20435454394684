<template>
  <div class="pa-1 d-flex" v-if="!loading">

    <template v-if="store.userPermissions != 'Viewer'">

      <!-- Select -->
      <ButtonDropdown :active="true" :slot="true" v-if="isActiveTool('select') || isActiveTool('resize')"
        :tooltip="'Select'">
        <template v-slot:my-icon>
          <v-icon v-if="store.tools.select.subTool=='select'">
            mdi:mdi-cursor-default-outline
          </v-icon>
          <lassoIcon v-else width="23" height="23" :color="'black'"/>
        </template>
        <template v-slot:menu>
          <toggleButton
            :active="isActiveSubTool('select')"
            :tooltip="'Select'"
            @click="store.changeTool('select', 'select')">
            <v-icon>
              mdi:mdi-cursor-default-outline
            </v-icon>
          </toggleButton>
          <toggleButton
            :active="isActiveSubTool('lasso')"
            :tooltip="'Lasso'"
            @click="store.changeTool('select', 'lasso')">
            <lassoIcon width="23" height="23" :color="'black'"/>
          </toggleButton>
        </template> 
      </ButtonDropdown>
      <div v-if="false" style="position: absolute; left: 0px; top: 50px; border: 1px solid black"
        class="rounded-lg pa-1 d-flex">
        <toggleButton 
          :tooltip="'Select'" 
          @click="''">
          <v-icon v-if="store.tools.select.subTool=='select'" size="18">
            fa:fas fa-arrow-pointer
          </v-icon>
        </toggleButton>
        <toggleButton 
          :tooltip="'Lasso'" 
          @click="''">
          <lassoIcon width="20" height="20"/>
        </toggleButton>
      </div>
    
      <!-- Line -->
      <toggleButton
        v-if="isActiveTool('select') || isActiveTool('resize')"
        :tooltip="'Draw'" 
        @click="store.changeTool('draw', store.tools.draw.subTool)"
        :active="isActiveTool('draw')">
        <v-icon size="22">
          mdi: mdi-pencil
        </v-icon>
      </toggleButton>
      <div v-if="isActiveTool('draw')" class="d-flex">
        <toggleButton
          :tooltip="'Polyline'" 
          @click="store.changeTool('draw', 'pen')"
          :active="isActiveSubTool('pen')">
          <v-icon>
            mdi:mdi-vector-polyline
          </v-icon>
        </toggleButton>
        <toggleButton
          :tooltip="'Line'" 
          @click="store.changeTool('draw', 'pencil')"
          :active="isActiveSubTool('pencil')">
          <v-icon>
            mdi:mdi-vector-line
          </v-icon>
        </toggleButton>
        <SectionSizeDropDown :inputSize="store.frameSize" @sizeSelected="store.frameSize=$event"/>
        <DoneBtn @click="store.changeTool('select', store.tools.select.subTool)"></DoneBtn>
      </div>
      
      <!-- Support -->
      <toggleButton v-if="isActiveTool('select') || isActiveTool('resize')" 
        :tooltip="'Supports'" 
        @click="store.changeTool('supports', store.tools.supports.subTool)"
        :active="isActiveTool('supports')">
        <v-icon size="20">
          mdi:mdi-triangle-outline
        </v-icon>
      </toggleButton>
      <div v-if="store.activeTool=='supports'" class="d-flex align-center">
        <toggleButton 
          :tooltip="'Pin'" :active="isActiveSubTool('pin')"
          @click="store.changeTool('supports', 'pin')">
          <v-icon>
          mdi:mdi-triangle-outline
        </v-icon>
        </toggleButton>
        <toggleButton 
          :tooltip="'Fix'" :active="isActiveSubTool('fix')"
          @click="store.changeTool('supports', 'fix')">
          <fixIcon/>
        </toggleButton>
        <toggleButton 
          :tooltip="'RollerX'" :active="isActiveSubTool('rollerX')"
          @click="store.changeTool('supports', 'rollerX')">
          <rollerX/>
        </toggleButton>
        <toggleButton 
          :tooltip="'RollerY'" :active="isActiveSubTool('rollerY')"
          @click="store.changeTool('supports', 'rollerY')">
          <rollerY/>
        </toggleButton>
        <DoneBtn @click="store.changeTool('select', store.tools.select.subTool)"></DoneBtn>
      </div>

      <!-- Point Load -->
      <toggleButton 
        v-if="isActiveTool('select') || isActiveTool('resize')" 
        class="d-flex align-center"
        :tooltip="'Point Load'" 
        @click="store.changeTool('loads', store.tools.loads.subToolPoint)"
        :active="isActiveTool('PointLoad')">
        <v-icon size="25">
          mdi:mdi-arrow-down
        </v-icon>
      </toggleButton>

      <div v-if="store.activeTool=='loads' && store.subTool.includes('point')" class="d-flex align-center">
        <toggleButton 
          class="d-flex align-center"
          @click="store.changeTool('loads', 'pointDown')"
          :active="isActiveSubTool('pointDown')">
          <v-icon size="25" class="mt-1">
            mdi:mdi-arrow-down
          </v-icon>
        </toggleButton>
        <toggleButton 
          class="d-flex align-center"
          @click="store.changeTool('loads', 'pointUp')"
          :active="isActiveSubTool('pointUp')">
          <v-icon size="25" class="mt-1">
            mdi:mdi-arrow-up
          </v-icon>
        </toggleButton>
        <toggleButton 
          class="d-flex align-center"
          @click="store.changeTool('loads', 'pointRight')"
          :active="isActiveSubTool('pointRight')">
          <v-icon size="25" class="mt-1">
            mdi:mdi-arrow-right
          </v-icon>
        </toggleButton>
        <toggleButton 
          class="d-flex align-center" 
          @click="store.changeTool('loads', 'pointLeft')"
          :active="isActiveSubTool('pointLeft')">
          <v-icon size="25" class="mt-1">
            mdi:mdi-arrow-left
          </v-icon>
        </toggleButton>
        <NumberInput 
          @valueChange="valueChange($event, 'pointLoad')"
          :input="Number(store.pointLoad)" :label="store.getPointLoadUnit" class="mx-2 mt-1">
        </NumberInput>
        <!-- <toggleButton 
          class="mr-2"
          :tooltip="'Point Load'" 
          @click="store.changeTool('loads', 'pointHorz')"
          :active="isActiveSubTool('pointHorz') && store.pointLoad<0">
          SDL
        </toggleButton> -->
        <DoneBtn @click="store.changeTool('select', store.tools.select.subTool)"></DoneBtn>
      </div>

      <!-- Line Load -->
      <toggleButton 
        v-if="isActiveTool('select') || isActiveTool('resize')" 
        class="d-flex align-center"
        :tooltip="'Line Load'" 
        @click="store.changeTool('loads', store.tools.loads.subToolLine)"
        :active="isActiveTool('LineLoad')">
        <LineLoad />
      </toggleButton>

      <div v-if="store.activeTool=='loads' && store.subTool.includes('line')" class="d-flex align-center">
        <toggleButton 
          class="d-flex align-center"
          @click="store.changeTool('loads', 'lineDown')"
          :active="isActiveSubTool('lineDown')">
          <LineLoad />
        </toggleButton>
        <toggleButton 
          class="d-flex align-center"
          @click="store.changeTool('loads', 'lineUp')"
          :active="isActiveSubTool('lineUp')">
          <LineLoad style="transform:rotate(180deg)"/>
        </toggleButton>
        <toggleButton 
          class="d-flex align-center"
          @click="store.changeTool('loads', 'lineRight')"
          :active="isActiveSubTool('lineRight')">
          <LineLoad style="transform:rotate(-90deg)"/>
        </toggleButton>
        <toggleButton 
          class="d-flex align-center" 
          @click="store.changeTool('loads', 'lineLeft')"
          :active="isActiveSubTool('lineLeft')">
          <LineLoad style="transform:rotate(90deg)"/>
        </toggleButton>
        <toggleButton 
          class="mr-2"
          :tooltip="'Apply load as distributed point-masses for mode calculations'" 
          @click="store.changeTool('loads', 'lineMass')"
          :active="isActiveSubTool('lineMass')">
          <WaveIcon :color="'black'" width="28" height="28" :strokeWidth="24"/>
          <v-icon size="10" color="grey" style="position:absolute; margin-left: -10px; margin-top:-12px">fa:fas fa-circle</v-icon>
          <v-icon size="10" color="grey" style="position:absolute; margin-right: -10px; margin-top: 12px">fa:fas fa-circle</v-icon>
        </toggleButton>
        <NumberInput 
          @valueChange="valueChange($event, 'lineLoad')"
          :input="Number(store.lineLoad)" :label="store.getLineLoadUnit" class="mx-2 mt-1">
        </NumberInput>
        <DoneBtn @click="store.changeTool('select', store.tools.select.subTool)"></DoneBtn>
      </div>
      
      <v-divider vertical thickness="2" class="border-opacity-100 mx-1 my-1"></v-divider>

    </template>

    <!-- Run -->
    <toggleButton
      :tooltip="'Analyze'" 
      @click="analyze">
      <v-icon color="primary" size="23" style="margin-left: 3px">
        fa:fa-solid fa-play
      </v-icon>
    </toggleButton>

  </div>
  <v-dialog v-model="outOfComputes" location-strategy="static" style="width: 500px">
    <v-card >
      <v-card-title class="d-flex justify-center">You Have Used Up All Your Free Computes</v-card-title>
      <v-card-subtitle class="d-flex justify-center">Upgrade your plan to access all the features of ForceSketch</v-card-subtitle>
      <div class="px-8 py-4">
      <v-btn @click="$router.push('/account')" block color="orange">Upgrade</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { useStore } from '@/store/store'
import toggleButton from '@/components/ui/toggleButton.vue'
import History from "@/utilities/History";
import * as modifiers from '@/utilities/Modifiers'
import lassoIcon from '@/assets/icons/lassoIcon.vue'
import fixIcon from '@/assets/icons/fixIcon.vue'
import rollerX from '@/assets/icons/rollerX.vue'
import rollerY from '@/assets/icons/rollerY.vue'
import gravityIcon from '../assets/icons/gravityIcon.vue'
import SectionSizeDropDown from '@/components/ui/SectionSizeDropDown.vue'
import NumberInput from '@/components/ui/NumberInput.vue'
import LineLoad from '../assets/icons/lineLoad.vue';
import ButtonDropdown from '@/components/ui/ButtonDropdown.vue';
import DoneBtn from '@/components/ui/DoneBtn.vue';
import WaveIcon from '@/assets/icons/waveIcon.vue'

export default {
  components: { 
    toggleButton, 
    lassoIcon, 
    fixIcon, 
    rollerX, 
    rollerY, 
    gravityIcon, 
    SectionSizeDropDown,
    NumberInput,
    LineLoad,
    ButtonDropdown,
    DoneBtn,
    WaveIcon
  },
  setup(){
    const store = useStore()
    return {store, History, modifiers}
  },
  data: () => ({
    user: null,
    outOfComputes: false,
    computeLog: null,
    loading: false
  }),
  async created(){
    this.loading = true
    this.user = await this.store.getUser()
    this.computeLog = await this.store.getComputeLog()
    this.loading = false
  },
  computed: {},
  mounted() {},
  methods: {
    isActiveTool(toolName){
      if (this.store.activeTool === toolName ) return true
      else return false
    },
    isActiveSubTool(toolName){
      if (this.store.subTool == toolName) return true
      else return false
    },
    valueChange(e, prop){
      this.store[prop] = e
      if (prop.includes('Scale')){
        var forceToScale = prop.replace('Scale', '')
        this.store.analyze.redrawResult(forceToScale)
      }
    },
    async analyze(){
      let computeSuccessful = await this.store.toggleAnalyzeMode()
      if (computeSuccessful){
        try{
          let incrementedCompute = await this.$api({
            url: "/api/computes/_computes/increment-compute",
            method: "POST",
            data: {
              auth0User: this.user
            }
          })
          this.store.setComputeLog(incrementedCompute.data)
        } catch(error){
          
        }
      }
    }
  }
}
</script>
