import paper from 'paper'
import * as utils from '../utilities/Utils.js'
import * as styles from '../styles/paperStyles.js'
import {useStore} from '@/store/store.js'
import History from "@/utilities/History"
import {LineCommand, BatchCommand, removeCommandHelper, LoadCommand} from "@/utilities/Commands"
import * as modifiers from "@/utilities/Modifiers.js"

export class Draw{
  store = useStore()
  constructor(){
    this.subTool = this.store.onMobile ? 'pencil' : 'pen'
    this.snappingPoint = null
    this.snappingIcon = null
    this.path = new paper.Path(styles.line)
    this.drawingNow = false
    this.firstTime = null
    this.batchCommand = new BatchCommand()
  }
  activate(subTool){
    if (subTool) this.subTool = subTool
    if (subTool == 'pen'){
      this.store.canvas.tool.onMouseMove = this.penOnMouseMove
      document.ondblclick = this.penStopDrawing
      document.onkeyup = this.keyPress
      if (this.store.onMobile){
        this.store.canvas.tool.onMouseUp = this.penOnMouseDown
        this.store.canvas.tool.onMouseDown = this.mobilepenOnMouseDown
        this.firstTime = true
      }
      else {
        this.store.hintMessage = "Press 'esc' to start a new line"
        this.store.canvas.tool.onMouseDown = this.penOnMouseDown
        this.firstTime = false
      }
    }
    if (subTool == 'pencil'){
      this.store.canvas.tool.onMouseDown = this.penStopDrawing
      this.store.canvas.tool.onMouseUp = this.pencilOnMouseUp
      this.store.canvas.tool.onMouseDrag = this.pencilOnMouseDrag
    }
  }
  keyPress = (e) => {
    if (e.key == 'Escape'){
      this.penStopDrawing()
    }
  }
  mobilepenOnMouseDown = (e) => {
    if (this.firstTime){
      var [closestPoint, snapType] = this.GetSnappingPoint(e.point, 10)
      if (this.path.segments.length == 0  && this.store.onMobile){
        this.drawingNow = true
        this.#startNewPath(closestPoint)
        this.drawSnappingIcon(closestPoint, snapType)
      }
    }
  }
  penOnMouseMove = (e) => {
    if (e.event.screenX == -1 || e.event.screenY == -1){
      return
    }
    var [closestPoint, snapType] = this.GetSnappingPoint(e.point, 10)
    this.drawSnappingIcon(closestPoint, snapType)
    
    //display length text
    if (this.drawingNow){
      if (this.path.segments.length == 1){
        let firstPoint = this.path.segments[0]
        this.path.add(firstPoint.point)
      }
      let lastPoint = this.path.segments.at(-2).point
      this.path.lastSegment.point = closestPoint
      var vector = closestPoint.subtract(lastPoint)
      let gridCellSizeProjected = this.store.grid.cellSize/this.store.grid.canvasZoom
      this.store.lineProps = {
        length: ((vector.length/gridCellSizeProjected)*this.store.grid.realGridSpacing).toFixed(2),
        x: Math.abs((vector.x/gridCellSizeProjected)*this.store.grid.realGridSpacing).toFixed(2),
        y: Math.abs((vector.y/gridCellSizeProjected)*this.store.grid.realGridSpacing).toFixed(2),
      }
      this.firstTime = false
    }
  }
  penOnMouseDown = (e) => {
    if (e.event.button && e.event.button != 0) return
    if (this.firstTime){
      this.firstTime = false
      return
    }
    this.drawingNow = true
    var [closestPoint, snapType] = this.GetSnappingPoint(e.point, 10)
    
    //if new point hits existing element, split the element
    this.hitAndSplit(closestPoint, null, this.batchCommand)
    if (this.path.segments.length == 0){
      this.#startNewPath(closestPoint)
      this.drawSnappingIcon(closestPoint, snapType)
    }
    else {
      if (this.path.segments.length == 1){
        this.path.add(closestPoint)
      }
      this.path.lastSegment.point = closestPoint
      if (this.path.length > 0){
        this.addEndNode(this.path, 1)
        this.store.lineProps = null
        this.addFrameProps(this.path)

        if (this.batchCommand.commands.length > 0) {
          this.batchCommand.add(new LineCommand(this.path, true))
          History.add(this.batchCommand)
          this.batchCommand = new BatchCommand()
        }
        else History.add(new LineCommand(this.path, true))

        this.#startNewPath(closestPoint)
      }
    }
  }
  penStopDrawing = (e) => {
    if (this.snappingIcon){
      this.snappingIcon.remove()
    }
    this.store.lineProps = null
    if (this.subTool == 'pen'){
      this.path.segments.pop()
    }
    let nodes = utils.findLineNodes(this.path)
    nodes.forEach(node => node.remove())
    this.path.remove()
    this.path = new paper.Path(styles.line())
    this.drawingNow = false
  }
  pencilOnMouseDown = (e) => {
    if (!this.path){
      this.path = this.#createPath();
    }
    this.path.add(e.point);
  }
  pencilOnMouseDrag = (e) => {
    this.path.add(e.point);
  }
  pencilOnMouseUp = (e) => {
    this.path.add(e.point)
    var start = this.path.segments[0]
    var end = this.path.segments.at(-1)
    var diffX = Math.abs(start.point.x - end.point.x)
    var diffY = Math.abs(start.point.y - end.point.y)
    var newStart
    var newEnd
    if (this.store.grid.gridOn && this.store.snapToGrid){
      var [startPt, snapType] = this.GetSnappingPoint(start.point, 40)
      var [endPt, snapType] = this.GetSnappingPoint(end.point, 40)
      newStart = startPt
      newEnd = endPt
    }
    else{
      if (diffY < 40){
        newStart = start.point
        newEnd = {x: end.point.x, y: start.point.y}
      }
      if (diffX < 40){
        newStart = start.point
        newEnd = {x: start.point.x, y: end.point.y}
      }
      if (newStart == null){
        newStart = start.point
      }
      if (newEnd == null){
        newEnd = end.point
      } 
      var [startPt, snapType] = this.GetSnappingPoint(newStart, 50)
      var [endPt, snapType] = this.GetSnappingPoint(newEnd, 50)
      newStart = startPt
      newEnd = endPt
    }
    //if new point hits existing element, split the element
    this.hitAndSplit(newStart, null, this.batchCommand)
    this.hitAndSplit(newEnd, null, this.batchCommand)
    this.path.remove()
    this.path = this.#createPath()
    this.path.add(newStart, newEnd)
    if (this.path.length > 40){
      this.addFrameProps(this.path)
      this.store.drawingLayer.addChild(this.path)
      if (this.batchCommand.commands.length > 0) {
        this.batchCommand.add(new LineCommand(this.path, true))
        History.add(this.batchCommand)
        this.batchCommand = new BatchCommand()
      }
      else History.add(new LineCommand(this.path, true))
      this.addEndNode(this.path)
    }
    this.path = this.#createPath()
  }
  deactivate(){
    if (this.snappingIcon){
      this.snappingIcon.remove()
    }
    this.store.lineProps = null
    if (this.subTool == 'pen'){
      this.path.segments.pop()
      this.path.segments.pop()
    }
    let nodesToRemove = utils.findLineNodes(this.path)
    nodesToRemove.forEach(node => node.remove())
    this.path.remove()
    this.path = new paper.Path(styles.line())
    this.drawingNow = false
    this.store.canvas.tool.onMouseDown = null
    this.store.canvas.tool.onMouseMove = null
    this.store.canvas.tool.onMouseUp = null
    this.store.canvas.tool.onMouseDrag = null
    this.store.hintMessage = null
    document.ondblclick = null
    document.onkeyup = null
  }
  drawSnappingIcon(closestPoint, snapType){
    if (this.snappingIcon){
      this.snappingIcon.remove()
    }
    let zoomScaleFactor = this.store.grid.canvasZoom
    if (snapType.includes('element')){
      var color = styles.secondaryColor
      if (snapType.includes('End')){
        var outerCircle = new paper.Path.Circle({center: closestPoint, radius: 6/zoomScaleFactor})
        outerCircle.strokeColor = color
        outerCircle.strokeWidth = 2/zoomScaleFactor
        this.snappingIcon = outerCircle
        outerCircle.removeOnMove()
        return
      }
      else if (snapType.includes('MidPoint')){
        let radius = 8 /zoomScaleFactor
        let crossAdd = radius * 1.5
        var outerCircle = new paper.Path.Circle({center: closestPoint, radius: radius})
        let vertLine = new paper.Path(closestPoint.add({x: 0, y: crossAdd}), closestPoint.add({x: 0, y: -crossAdd}))
        let horzLine = new paper.Path(closestPoint.add({x: crossAdd, y:0}), closestPoint.add({x: -crossAdd, y: 0}))
        let iconGroup = new paper.Group(outerCircle, vertLine, horzLine)
        iconGroup.strokeColor = color
        iconGroup.strokeWidth = 2/zoomScaleFactor
        this.snappingIcon = iconGroup
        iconGroup.removeOnMove()
      } 
      else {
        var circle = new paper.Path.Circle({center: closestPoint, radius: 8/zoomScaleFactor})
        circle.fillColor = color
        this.snappingIcon = circle
        circle.removeOnMove()
      }
      return
    }
    else if (snapType.includes('grid')){
      var color = 'blue'
      var line1 = new paper.Path([closestPoint.x, closestPoint.y-10/zoomScaleFactor], [closestPoint.x, closestPoint.y+10/zoomScaleFactor])
      var line2 = new paper.Path([closestPoint.x-10/zoomScaleFactor, closestPoint.y], [closestPoint.x+10/zoomScaleFactor, closestPoint.y])
      var crossGroup = new paper.Group(line1, line2)
      crossGroup.strokeWidth = 2/zoomScaleFactor
      crossGroup.strokeColor = color
      this.snappingIcon = crossGroup
    }
    else if (snapType == 'orthoX'){
      var color = 'blue'
      var line1 = new paper.Path([closestPoint.x, closestPoint.y-10/zoomScaleFactor], [closestPoint.x, closestPoint.y+10/zoomScaleFactor])
      line1.strokeWidth = 2/zoomScaleFactor
      line1.strokeColor = color
      this.snappingIcon = line1
    }
    else if (snapType == 'orthoY'){
      var color = 'blue'
      var line2 = new paper.Path([closestPoint.x-10/zoomScaleFactor, closestPoint.y], [closestPoint.x+10/zoomScaleFactor, closestPoint.y])
      line2.strokeWidth = 2/zoomScaleFactor
      line2.strokeColor = color
      this.snappingIcon = line2
    }
  }
  #startNewPath(snappingPoint){
    this.path = new paper.Path(styles.line())
    this.path.add(snappingPoint)
    this.store.drawingLayer.addChild(this.path)
    this.addEndNode(this.path, 0)
  }
  addEndNode(line, endToAddNodeTo){
    line.segments.forEach((segment, index) => {
      if (endToAddNodeTo && index != endToAddNodeTo) return 
      const node = new paper.Path.Circle(segment.point, 6)
      node.style = styles.node()
      node.scale(1/this.store.grid.canvasZoom)
      node.data.lineId = line._id
      node.data.location = segment.point
      node.data.lineSegmentIndex = index
      node.data.layer = 'node'
      this.store.nodeLayer.addChild(node)
    })
  }
  #createPath() {
    return new paper.Path(styles.line())
  }
  hitAndSplit(splitPoint, lineToSplit, batchCommand){
    if (!lineToSplit){
      let hitResult = this.store.drawingLayer.hitTest(splitPoint, {stroke: true, curves: true, tolerance: 0})
      if (hitResult) {
        lineToSplit = hitResult.item
        splitPoint = hitResult.point
      } 
    }
    if (lineToSplit){
      if (splitPoint && 
        (utils.distance2Points(lineToSplit.segments[0].point, splitPoint) < 0.3 ||  
        utils.distance2Points(lineToSplit.segments[1].point, splitPoint) < 0.3)) return 
      let savedLoadData = []
      let loadsToRemove = utils.findAssociatedLoads(lineToSplit)
      loadsToRemove.forEach(load => {
        savedLoadData.push(load.data)
        batchCommand.add(removeCommandHelper(load))
        modifiers.remove(load)
      })
      var segment1 = new paper.Path(lineToSplit.firstSegment, splitPoint)
      var segment2 = new paper.Path(splitPoint, lineToSplit.lastSegment)
      segment1.data = JSON.parse(JSON.stringify(lineToSplit.data))
      segment2.data = JSON.parse(JSON.stringify(lineToSplit.data))
      segment1.style = styles.line()
      segment2.style = styles.line()
      this.addMemberSizeText(segment1)
      this.addMemberSizeText(segment2)
      this.addDimensions(segment1)
      this.addDimensions(segment2)
      this.addEndNode(segment1)
      this.addEndNode(segment2)
      this.store.drawingLayer.addChild(segment1)
      this.store.drawingLayer.addChild(segment2)
      savedLoadData.forEach(async (data) => {
        let isLineMass = data.type == 'Line Mass' ? true : false
        let load1 = await this.store.tools.loads.scaleLineLoad(segment1, data.magnitude, data.dir, isLineMass)
        let load2 = await this.store.tools.loads.scaleLineLoad(segment2, data.magnitude, data.dir, isLineMass)
        this.store.tools.loads.addLoadText(load1)
        this.store.tools.loads.addLoadText(load2)
        batchCommand.add(new LoadCommand(load1, true))
        batchCommand.add(new LoadCommand(load2, true))
      })
      let removeCommand = modifiers.remove(lineToSplit)
      removeCommand.forEach(command => batchCommand.add(command))
      batchCommand.add(new LineCommand(segment1, true))
      batchCommand.add(new LineCommand(segment2, true))
    }
    return batchCommand
  }
  addFrameProps(path){
    path.data.end = 'fixed'
    path.data.start = 'fixed'

    let frameType = utils.getFrameType(path)
    path.data.frameProps = {
      type: frameType,
      size: this.store.frameSize,
      orientation: 0
    }
    path.data.layer = this.store.drawingLayer.name
    this.addMemberSizeText(path)
    this.addDimensions(path)
  }
  addMemberSizeText(path){    
    var midPoint = path.bounds.center
    var text = new paper.PointText(midPoint)
    text.style = styles.memberSize
    text.content = path.data.frameProps.size.name
    text.data.elementId = path.id
    text.leading = 0

    text.rotate(modifiers.textRotation(path), midPoint)
    text.scale(1/this.store.grid.canvasZoom)
    this.store.memberSizesLayer.addChild(text)
    if (this.store.selectedItems.map(i => i._id).includes(path._id)){
      text.fillColor = styles.highlightColor
    }
  }
  addDimensions(path){
    let [pathStart, pathEnd] = utils.reorientLine(path)
    let tempPath = new paper.Path(pathEnd, pathStart)
    let switched = false
    if (!pathStart.equals(path.segments[0].point)){
      switched = true
    }
    let normal = tempPath.getNormalAt(0)
    tempPath.remove()
    let scaleFactor = this.store.grid.canvasZoom
    let dimStart = new paper.Path(
      pathStart.add({x: normal.x*10/scaleFactor, y: normal.y*10/scaleFactor}), 
      pathStart.add({x: normal.x*40/scaleFactor, y: normal.y*40/scaleFactor})
    )
    let dimStyle = styles.dimStyle
    dimStyle.strokeWidth = 1/this.store.grid.canvasZoom
    dimStart.style = dimStyle
    let dimEnd = new paper.Path(
      pathEnd.add({x: normal.x*10/scaleFactor, y: normal.y*10/scaleFactor}), 
      pathEnd.add({x: normal.x*40/scaleFactor, y: normal.y*40/scaleFactor})
    )
    dimEnd.style = dimStyle
    let dimLine = new paper.Path(dimStart.getPointAt(dimStart.length/2), dimEnd.getPointAt(dimStart.length/2))
    dimLine.style = dimStyle
    let textLocation = {x: dimLine.bounds.centerX, y: dimLine.bounds.centerY}
    let dimText = new paper.PointText(textLocation)
    dimText.style = styles.dimTextGrey
    dimText.scale(1/this.store.grid.canvasZoom)
    let gridCellSizeProjected = this.store.grid.cellSize/this.store.grid.canvasZoom
    dimText.content = utils.Round((path.length/gridCellSizeProjected)*this.store.grid.realGridSpacing, 2)
    dimText.rotate(modifiers.textRotation(path), textLocation)
    let dimensionGroup = new paper.Group([dimStart, dimEnd, dimLine, dimText])
    if (switched){
      dimensionGroup.data.start = pathEnd
      dimensionGroup.data.end = pathStart
    }
    else {
      dimensionGroup.data.start = pathStart
      dimensionGroup.data.end = pathStart
    }
    dimensionGroup.data.normal = normal
    dimensionGroup.data.elementId = path.id
    this.store.dimensionLayer.addChild(dimensionGroup)
  }  
  GetSnappingPoint(e, tolerance, lineToSnapTo){
    if (!lineToSnapTo) lineToSnapTo = this.store.drawingLayer;

    let closestPoint;
    let snapType;
    tolerance /= this.store.grid.canvasZoom; 

    const hitOptions = {
      stroke: true,
      fill: true,
      curves: true,
      ends: true,
      tolerance: tolerance,
    };

    let [orthoClosestPoint, direction] = this.drawOrtho(e)

    // Snap to grid point if grid snapping is enabled
    if (this.store.gridOn && this.store.snapToGrid) {
      let gridSnappingPoint = this.store.grid.getSnappingPoint(e);
      let gridDistance
      
      //Draw Ortho Only
      if (orthoClosestPoint){
        let orthoGridPoint, snapType
        if (direction == 'x') {
          orthoGridPoint = new paper.Point(gridSnappingPoint.x, orthoClosestPoint.y)
          snapType = 'orthoX'
        }
        else {
          orthoGridPoint = new paper.Point(orthoClosestPoint.x, gridSnappingPoint.y)
          snapType = 'orthoY'
        }
        let gridSnappingPoint2 = this.store.grid.getSnappingPoint(orthoGridPoint);
        if (utils.pointsSameLocation(gridSnappingPoint2, orthoGridPoint)) snapType = 'grid'
        return [orthoGridPoint, snapType]
      }
      else {
        closestPoint = gridSnappingPoint
        gridDistance = utils.distance2Points(closestPoint, e)
        snapType = 'grid'
      }

      if (lineToSnapTo.children.length > 0) {
        let hitResults = lineToSnapTo.hitTestAll(e, hitOptions);
        
        // Snap to element if closer to element than grid
        hitResults.forEach(hitResult => {
          if (!this.path || hitResult.item.id !== this.path.id) {
            let hitItem = hitResult.item;
            let elemdistance;

            if (hitResult.type === 'segment') {
              // Snap to the end of a line segment
              closestPoint = hitResult.point;
              snapType = 'elementEnd';
            } else if (hitItem) {
              //Check if closest to midpoint
              let midPoint = hitItem.bounds.center;
              let midPointElemdistance = utils.distance2Points(midPoint, e);
              if (midPointElemdistance < gridDistance * 2) {
                closestPoint = midPoint;
                snapType = 'elementMidPoint';
                return 
              }
              //Check if closer to element than grid
              elemdistance = utils.distance2Points(hitResult.location.point, e)
              if (elemdistance < gridDistance / 4) {
                snapType = 'element';
                closestPoint = hitResult.location.point;
                elemdistance = hitResult.location.distance;
              }
            }
          }
        });
      }
    } 
    else {
      // No grid snapping, default to the event point
      closestPoint = e;
      snapType = 'nothing';
      
      if (lineToSnapTo.children.length > 0) {
        // let testPoint = orthoClosestPoint ? orthoClosestPoint : e
        let hitResults = lineToSnapTo.hitTestAll(e, hitOptions);
        hitResults.forEach(hitResult => {
          if (hitResult.item.id !== this.path._id) {
            let hitItem = hitResult.item;
            let elemdistance;

            if (hitResult.type === 'segment') {
              // Snap to the end of a line segment
              closestPoint = hitResult.point;
              snapType = 'elementEnd';
            } 
            else if (hitItem) {
              let midPoint = hitItem.bounds.center;
              elemdistance = utils.distance2Points(midPoint, e);

              if (elemdistance < tolerance * 2) {
                // Snap to the midpoint of an element
                closestPoint = midPoint;
                snapType = 'elementMidPoint';
              } else {
                // Snap to the closest point on the element
                closestPoint = hitResult.location.point;
                snapType = 'element';
              }
            }
            else {
              console.log('here')
              let orthoClosestPoint = this.drawOrtho(e)
              if (orthoClosestPoint){ 
                
                closestPoint = orthoClosestPoint
                snapType = 'ortho'
              }
            }
          }
        });
      }
    }
    if (snapType == 'nothing'){
      let [orthoPoint, direction] = this.drawOrtho(e)
      if (orthoPoint) closestPoint = orthoPoint
    }
    return [closestPoint, snapType];
  }
  drawOrtho(e){
    if (this.store.drawOrtho && this.drawingNow && this.path.segments.length > 1){
      let lineStartPoint = this.path.segments[0].point
      if (Math.abs(e.x - lineStartPoint.x) > Math.abs(e.y - lineStartPoint.y)) {
        let closestPoint = new paper.Point(e.x, lineStartPoint.y)
        return [closestPoint, 'x']
      }
      else {
        let closestPoint = new paper.Point(lineStartPoint.x, e.y)
        return [closestPoint, 'y']
      }
    }
    else return [null, null]
  }

  checkOrthoSnap(point, currentPos) {
    let deltaX = Math.abs(point.x - currentPos.x);
    let deltaY = Math.abs(point.y - currentPos.y);
    
    // Return the point only if it's aligned to either the x or y axis (orthogonal)
    if (deltaX === 0 || deltaY === 0) {
      return point;
    }
    return null;
  }
}