import { useStore } from '@/store/store'
import * as styles from '../styles/paperStyles'
import * as commands from "@/utilities/Commands"
import * as modifiers from '@/utilities/Modifiers'
import History from '@/utilities/History.js'
import paper from 'paper'
import * as utils from '@/utilities/Utils'
import {paste, move} from '@/composables/CopyPasteMove.js'

export class Select {
  store = useStore()
  constructor() {
    this.subTool = 'select'

    //Select Props
    this.hitOptions = {
      stroke: true,
      curves: true,
      segments: true,
      tolerance: 10,
    },
    this.selectionBox = null
    this.selectionBoxOrigin = null
    this.shiftKeyPressed = false,
    this.closestPoint = null

    //Node Editing Props
    this.selectedNodes = [],
    this.nodeMoveCommands = [],
    this.linesNotSelected = new paper.Group()
    this.tempLassoSelectedElements = []
    this.assocSupports = []
    this.assocPointLoads = []
    this.assocReleases = []
    this.assocLineLoads = []

    //Lasso Props
    this.lassoStartPoint = null;
    this.lasso = null;
    this.lassoClosed = false

    //Moving Elements
    this.moveElements = false
    this.lastMovePoint = null
  }
  activate(subTool) {
    this.subTool = subTool
    if (this.subTool == 'select') {
      this.store.canvas.tool.onMouseDown = this.onMouseDown;
      this.store.canvas.tool.onMouseDrag = this.onMouseDrag;
      this.store.canvas.tool.onMouseUp = this.onMouseUp
      this.store.canvas.tool.onKeyDown = this.shiftKeyDown
      this.store.canvas.tool.onKeyUp = this.shiftKeyUp
      this.store.canvas.tool.onMouseMove = this.onMouseMove
    }
    else if (this.subTool == 'lasso') {
      this.store.canvas.tool.onMouseDown = this.onMouseDownLasso;
      this.store.canvas.tool.onMouseDrag = this.onMouseDragLasso;
      this.store.canvas.tool.onMouseUp = this.onMouseUpLasso
    }
  }
  shiftKeyDown = (e) => {
    if (e.key === "shift") {
      this.shiftKeyPressed = true
    }
  }
  shiftKeyUp = (e) => {
    if (e.key === "shift") {
      this.shiftKeyPressed = false
    }
  }
  onMouseMove = (e) => {
    if (!(this.store.copy || this.store.moving)) return 
    
    var [closestPoint, snapType] = this.store.tools.draw.GetSnappingPoint(e.point, 10)
    this.store.tools.draw.drawSnappingIcon(closestPoint, snapType)
    this.closestPoint = closestPoint
  }
  onMouseDown = (e) => {
    if (e.event.button == 1 || e.event.button == 2) {
      return
    } 

    //Moving Mode
    if (this.store.moving){
      if (!this.store.moveStartPoint) {
        this.store.moveStartPoint = this.closestPoint
        this.store.snackbarText = 'Select Point To Move To'
        this.store.snackbarTimeOut = -1
        this.store.snackbar = false
        this.store.snackbar = true
      }
      else {
        let translationVector = this.closestPoint.subtract(this.store.moveStartPoint)
        move(translationVector)
      }
    }
    //In Copy/Paste Mode
    else if (this.store.copy){
      if (!this.store.startPastePoint) {
        this.store.startPastePoint = this.closestPoint
        this.store.snackbarText = 'Select Point To Copy To'
        this.store.snackbarTimeOut = -1
        this.store.snackbar = false
        this.store.snackbar = true
      }
      else {
        this.store.pastePoint = this.closestPoint
        paste()
      }
    }
    //Selection Mode
    else {
      let hitItem = this.getClickItem(e)
      if (hitItem){
        if (hitItem.data.layer == 'node'){
          if (this.store.selectedItems.length == 0){
            let nodesHit = utils.findNodesSameLocation(hitItem)
            let assocElements = []
            nodesHit.forEach(node => {
              let assocLine = utils.findLineByNode(node)
              assocElements.push(assocLine)
            })
            
              let supportsSameLocation = utils.findSupportSamePosition(nodesHit[0].data.location)
              supportsSameLocation.forEach(support => assocElements.push(support))
              let pointLoadsSameLocation = utils.findPointLoadSamePosition(nodesHit[0].data.location)
              pointLoadsSameLocation.forEach(pointLoad => assocElements.push(pointLoad))
            this.store.setSelectedItem(assocElements)
          }
          this.nodeHitMouseDown(hitItem)
        }
        else {
          if (this.shiftKeyPressed) this.store.addToSelection(hitItem, true)
          else this.store.setSelectedItem(hitItem)
        }
      }
      else {
        if (this.store.onMobile){
          this.store.setSelectedItem(null)
        }
        else {
          this.selectionBoxOrigin = e.point
          if (!this.shiftKeyPressed){
            this.store.setSelectedItem(null)
          }
        }
      }
      this.store.selectionComplete = false
    }
  };
  onMouseDrag = (e) => {
    if (e.event.button == 1 || e.event.button == 2) {
      return
    } 
    //move node
    if (this.selectedNodes.length > 0){
      this.nodeHitMouseDrag(e)
    }
    //create selection box
    else if (!this.store.onMobile){
      this.store.selectionComplete = false
      if (this.selectionBoxOrigin){
        if (this.selectionBox) this.selectionBox.remove()
        this.selectionBox = this.createSelectionBox(e)
        let selectedElements = []
        let allCanvasElements = this.store.getAllCanvasElements.elements
        if (allCanvasElements.length == 0) return
        allCanvasElements.flat().forEach(element => {
          if (this.selectionBoxIntersection(element, this.selectionBox)){ 
            if (!selectedElements.includes(element)) selectedElements.push(element)
          }
        }) 
        if (this.shiftKeyPressed){
          this.store.addToSelection(selectedElements, true)
        }
        else {
          this.store.setSelectedItem(selectedElements)
        }
      }
    }
  };
  onMouseUp = (e) => {
    if (e.event.button == 1 || e.event.button == 2) {
      return
    } 
    //move nodes
    if (this.selectedNodes.length > 0){
      this.nodeHitMouseUp()
    }
    //selection
    else {
      this.selectionBoxOrigin ? this.selectionBoxOrigin = null : ''
      this.selectionBox ? this.selectionBox.remove() : ''
      if (this.shiftKeyPressed){
        this.store.tempSelectionItems.forEach(item => {
          this.store.selectedItems.push(item)
        })
        this.store.tempSelectionItems = []
      }
    }
    this.selectedNodes = []
    this.store.selectionComplete = true
  }
  onMouseDownLasso = (e) => {
    //Node Editing
    let hitItem = this.getClickItem(e)
    if (hitItem && hitItem.data.layer == 'node'){
      this.nodeHitMouseDown(hitItem)
    }
    else {
      if (this.store.copy){
        this.recordPastePoint(e)
      }
      this.store.selectionComplete = false
      this.lassoStartPoint = new paper.Path(e.point)
      this.lasso = new paper.Path(styles.lasso)
      this.lasso.add(e.point)
      if (!this.shiftKeyPressed){
        this.store.setSelectedItem(null)
      }
    }
    this.store.selectionComplete = false
  };
  onMouseDragLasso = (e) => {
    if (this.selectedNodes.length > 0){
      this.nodeHitMouseDrag(e)
    }
    else {
      var hitOptions = {
        stroke: true,
        segments: true,
        tolerance: 10,
      }
      let lassoStartPointHit = this.lassoStartPoint.hitTest(e.point, hitOptions)
      if (lassoStartPointHit && this.lasso.length > 10){
        this.closeLasso()
      }
      if (!this.lassoClosed){
        this.lasso.add(e.point)
        this.addTempLassoItemsToSelected()
      }
    }
  };
  onMouseUpLasso = (e) => {
    //Nodes were moved
    if (this.selectedNodes.length > 0){
      this.nodeHitMouseUp()
    }
    //lasso
    else {
      if (!this.lassoClosed){
        this.closeLasso()
      }
      this.tempLassoSelectedElements.forEach(item => {
        this.store.selectedItems.push(item)
      })
      this.lasso.remove()
      this.lassoStartPoint.remove()
      this.tempLassoSelectedElements = []
      this.lassoClosed = false
    }
    this.store.selectionComplete = true
  }
  closeLasso(){
    this.lasso.add(this.lassoStartPoint.firstSegment.point)
    this.addTempLassoItemsToSelected()
    this.lassoClosed = true
  }
  addTempLassoItemsToSelected(){
    let allCanvasElements = this.store.getAllCanvasElements.elements
    if (allCanvasElements.length != 0) {
      allCanvasElements.flat().forEach(item => {
        if (this.lassoContains(item, this.lasso) || this.lassoIntersection(item, this.lasso)){
              let selectedElementIds = this.tempLassoSelectedElements.map(i => i.id)
              if (!selectedElementIds.includes(item.id)){
                modifiers.highlight(item)
                this.tempLassoSelectedElements.push(item)
              }
            }
        })
    }
  }
  recordPastePoint(e){
    if (this.store.snapToGrid){
      this.store.pastePoint = this.store.grid.getSnappingPoint(e.point)
    }
    else this.store.pastePoint = this.store.grid.getSnappingPoint(e.point)
  }
  nodeHitMouseDown(hitNode){
    let nodes = utils.findNodesSameLocation(hitNode)
    nodes.forEach(node => {
      let selectedNodeIds = this.selectedNodes.map(node => node.id)
      if (!selectedNodeIds.includes(node.id) && 
        (this.store.selectedItems.length > 0 && this.store.selectedItems.map(item => item._id).includes(node.data.lineId))){
        this.selectedNodes.push(node)
        let selectedElementIds = this.store.selectedItems.map(item => item._id)
        //Get associate node elements
        let assocElements = []
        let assocLine = utils.findLineById(node.data.lineId)
        //Point Loads
        let pointLoads = utils.findPointLoadSamePosition(hitNode.data.location)
        pointLoads.forEach(load => {
          if (!selectedElementIds.includes(load._id)) return
          let pointLoadText = utils.findAssociatedText(load)
          if (pointLoadText) pointLoadText.remove()
          if (!this.assocPointLoads.map(load => load._id).includes(load._id)){
            this.assocPointLoads.push(load)
            assocElements.push(load)
          }
        })
        //Supports
        let supports = utils.findSupportSamePosition(hitNode.data.location)
        supports.forEach(support => {
          if (!selectedElementIds.includes(support._id)) return
          if (!this.assocSupports.map(support => support._id).includes(support._id)){
            this.assocSupports.push(support)
            assocElements.push(support)
          }
        })
        //Line Text
        let lineText = utils.findAssociatedText(assocLine)
        if (lineText) lineText.remove()
        //Line Loads
        let assocLineLoads = utils.findAssociatedLoads(assocLine)
        assocLineLoads.forEach(load => {
          assocElements.push(load)
          this.assocLineLoads.push(load)
          let loadText = utils.findAssociatedText(load)
          if (loadText) loadText.remove()
          load.strokeColor = styles.highlightColor
          this.store.addToSelection(load)
        })
        //Releases
        let assocReleases = this.store.releaseLayer.children.filter(release => 
          release.data.elementId == node.data.lineId)
        assocReleases.forEach(release => {
          assocElements.push(release)
          this.assocReleases.push(release)
        })
        //Dimension
        let assocDims = this.store.dimensionLayer.children.filter(dim => 
          dim.data.elementId == node.data.lineId)
        assocDims.forEach(dim => dim.remove())
        //Make Commands
        let nodeMoveCommand = new commands.nodeMoveCommand(node, assocElements)
        this.nodeMoveCommands.push(nodeMoveCommand)
      }
      else {
        node.strokeColor = 'black'
      }
    })
    //create hit group of all non-selected lines
    this.linesNotSelected = new paper.Group()
    let selectedItemIds = this.store.selectedItems.map(item => item.id)
    this.store.drawingLayer.children.forEach(line => {
      if (!selectedItemIds.includes(line.id)){
        this.linesNotSelected.addChild(line.clone())
      }
    })
    this.linesNotSelected.removeOnUp()
  }
  nodeHitMouseDrag(e){
    var [snappingPoint, snapType] = this.store.tools.draw.GetSnappingPoint(e.point, 10, this.linesNotSelected)
    //Move Nodes
    this.selectedNodes.forEach(node => {
      node.position = snappingPoint
      //Move Lines
      let assocLine = utils.findLineById(node.data.lineId)
      assocLine.segments[node.data.lineSegmentIndex].point = snappingPoint
      //Move Line Loads
      let assocLineLoads = utils.findAssociatedLoads(assocLine)
      if (assocLineLoads.length > 0){
        assocLineLoads.forEach(async (load) => {
          let loadId = load._id
          load.remove()
          let isLineMass = load.data.type == 'Line Mass' ? true : false
          let newLoad = await this.store.tools.loads.scaleLineLoad(assocLine, load.data.magnitude, load.data.dir, isLineMass)
          newLoad._id = loadId
          newLoad.strokeColor = styles.highlightColor
        })
      }
    })
    //Move Supports
    this.assocSupports.forEach(support => {
      support.position = {x: snappingPoint.x, y: snappingPoint.y+support.bounds.height/2}
      support.data.location = snappingPoint
    })
    //Move Point Loads
    this.assocPointLoads.forEach(load => {
      load.position = snappingPoint
      this.store.tools.loads.translate(load, load.data.dir)
      load.data.location = snappingPoint
    })
    //Move Releases
    this.assocReleases.forEach(release => {
      let line = utils.findLineById(release.data.elementId)
      let newPosition = modifiers.getReleasePosition(line, release.data.end)
      release.position = newPosition
    })
  }
  nodeHitMouseUp(){
    //Redraw MemberSize Text
    this.selectedNodes.forEach((node, index) => {
      let assocLine = utils.findLineById(node.data.lineId)
      assocLine.data.frameProps.type = utils.getFrameType(assocLine)
      this.store.tools.draw.addDimensions(assocLine)
      this.store.tools.draw.addMemberSizeText(assocLine)
      this.nodeMoveCommands[index].addNodeAfterMove(node)
    }) 
    this.assocLineLoads.forEach(lineLoad => {
      //find current lineLoad
      let currentLineLoad = this.store.loadLayer.children.find(load => load._id == lineLoad.id)
      this.store.replaceInSelection(currentLineLoad)
      let text = this.store.tools.loads.addLoadText(currentLineLoad)
      text.fillColor = styles.highlightColor
    })
    this.assocPointLoads.forEach(pointLoad => {
      let text = this.store.tools.loads.addLoadText(pointLoad)
      text.fillColor = styles.highlightColor
    })
    //Create and add batchCommands
    let batchCommand = new commands.BatchCommand(this.nodeMoveCommands)
    History.add(batchCommand)
    this.nodeMoveCommands = []
    this.selectedNodes = []
    this.assocSupports = []
    this.assocLineLoads = []
    this.assocSupports = []
    this.assocPointLoads = []
    this.nodeMoveCommands = []
  }
  lassoIntersection(item, lasso){
    if (item.intersects(lasso)){
      return true
    }
  }
  lassoContains(item, lasso){
    if (lasso.contains(item.bounds.center)){
      return true
    }
  }
  selectionBoxIntersection(element, selectionBox){
    if (selectionBox.crossingSection == false && element.isInside(selectionBox.bounds)){
      return true
    }
    else if (selectionBox.crossingSection &&
      (element.intersects(selectionBox) || element.isInside(selectionBox.bounds))){ 
      return true
    }
    else return false
  }
  getClickItem(e){
    this.hitOptions.tolerance = 10/this.store.grid.canvasZoom
    var hits = [
      this.store.drawingLayer.hitTest(e.point, this.hitOptions),
      this.store.loadLayer.hitTest(e.point, this.hitOptions),
      this.store.supportLayer.hitTest(e.point, this.hitOptions),
      this.store.nodeLayer.hitTest(e.point, this.hitOptions)
    ];
    var hitItem;
    var closestDistance = Infinity;

    //get distance away from each element if within hit tolerance and find
    //closest element to mouse click
    if (hits[3]) {
      hitItem = hits[3].item
    }
    else if (hits[0] && hits[1]){
      hitItem = hits[0].item
    }
    else {
      hits.forEach((hit, index) => {
        if (hit && hit.location) {
          if (hit.location.distance < closestDistance) {
            closestDistance = hit.location.distance;
            if (index == 0) hitItem = hit.item;
            else {
              if (hit.item.parent.parent.className != "Layer") {
                hitItem = hit.item.parent.parent;
              } else hitItem = hit.item.parent;
            }
          }
        } 
        else if (hit) {
          if (index == 0) hitItem = hit.item;
          else {
            if (hit.item.parent.parent.className != "Layer") {
              hitItem = hit.item.parent.parent;
            } else hitItem = hit.item.parent;
          }
        }
      });
    }
    return hitItem
  }
  createSelectionBox(newPoint){
    let selectionBox = new paper.Path.Rectangle(this.selectionBoxOrigin, newPoint.point)
    selectionBox.fillColor = 'rgba(46, 196, 255, 0.11)';
    selectionBox.selected = true;
    if (newPoint.point.x < this.selectionBoxOrigin.x) selectionBox.crossingSection = true
    else selectionBox.crossingSection = false
    return selectionBox
  }
  deactivate() {
    this.store.canvas.tool.onMouseDown = null;
    this.store.canvas.tool.onMouseMove = null;
    this.store.canvas.tool.onMouseDrag = null;
    this.store.canvas.tool.onMouseUp = null
    this.selectionBox ? this.selectionBox.remove() : ''
    this.selectionBoxOrigin ? this.selectionBoxOrigin.remove() : ''
    this.store.setSelectedItem(null)
  }
}
